<template>
  <WorkshopReg />
</template>

<script>
import WorkshopReg from "@/components/workshop/WorkshopReg";

export default {
  name: "WorkshopRegistrationPage",
  props: {},
  components: {
    WorkshopReg,
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>
